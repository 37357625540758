/*###########################################################################
// Forms
//#########################################################################*/

const forms = {
	config: {
		hsForm: '.hs-form, .hs-form-private'
	},

	elements: {},

	init() {
		const self = this

		self.elements.hsForms = document.querySelectorAll(self.config.hsForm)

		if (self.elements.hsForms !== null) {
			for (let i = 0; i < self.elements.hsForms.length; i++) {
				const hsForm = self.elements.hsForms[i]
				const submitBtn = hsForm.querySelector('input[type="submit"]')

				self.handleFormButton(submitBtn)
			}
		}
	},

	handleFormButton(submitBtn) {
		const self = this

		// Create a new button
    const newButton = document.createElement('button');

    // Retain the existing classes
    newButton.className = submitBtn.className + ' btn';

    // Copy the text from the input's value
    newButton.textContent = submitBtn.value;

    // Ensure it's still treated as a form submission button
    newButton.type = 'submit';

    // Replace the original input with the new button
    submitBtn.parentNode.replaceChild(newButton, submitBtn);
	}
}

window.addEventListener('load', function () {
	setTimeout(() => {
		forms.init()
	}, 500)
})
