/*###########################################################################
// Reveal
//#########################################################################*/

import { throttle } from '../utilities/_throttleDebounce'
import { inViewport } from '../utilities/_inViewport'

const reveal = {
  config: {
    revealEl: '.reveal',
  },

  elements: {
    revealEls: null
  },

  init() {
    const self = this

    self.elements.revealEls = document.querySelectorAll(self.config.revealEl)

    if (self.elements.revealEls !== null) {
      for (let i = 0; i < self.elements.revealEls.length; i++) {
        const revealEl = self.elements.revealEls[i]
        self.handleRevealEl(revealEl)
      }
    }
  },

  handleRevealEl(revealEl) {
    const self = this

    let dividerValue = 12
    if (window.innerWidth < 768) {
      dividerValue = 16
    }

    // Check if in viewport already (scroll saved)
    //
    if (inViewport(revealEl, window.innerHeight/dividerValue) && !revealEl.classList.contains('revealed')) {
      revealEl.classList.add('revealed')
    }

    window.addEventListener('scroll', throttle(function() {
      if (inViewport(revealEl, window.innerHeight/dividerValue) && !revealEl.classList.contains('revealed')) {
        revealEl.classList.add('revealed')
      }
    }, 100))
  }
}

window.addEventListener('load', function () {
  reveal.init()
})
